import {
  Country,
  Literals,
  MyStuff,
  Notification,
  NotificationSetting,
  Project,
  ProjectCategory,
  SDG,
  Skill,
  SkillCategory,
  User,
} from 'redux/types/account';
import { EmailInvitationDto } from 'ApiModels';
import { AxiosError } from 'axios';
import { UserProfile } from 'UsersModels';
import { combineReducers } from 'redux';
import { createReducer, PayloadAction } from 'typesafe-actions';
import { findIndex, propEq } from 'ramda';
import { Languages } from '../types/enums';
import literals from '../../services/literals/literals.json';
import {
  applicationDefinitionsReducer,
  applicationDefinitionsTransform,
  applicationsReducer,
  applicationsTransform,
} from './application-manager';
import { getPersistKey, removeMyStuffItemDescription } from 'util/persist';
import storage from 'redux-persist/es/storage';
import { gdprConsent, featuresCookieCategories } from 'util/utils';
import { DEFAULT_USER } from 'redux/types/default-types-values';

import {
  clearHasPendingRequestsAsync,
  fetchCountriesAsync,
  fetchCurrentUserAsync,
  fetchMyStuffAsync,
  fetchNotificationsAsync,
  fetchNotificationSettingsAsync,
  fetchProjectCategoriesAsync,
  fetchSDGsAsync,
  fetchSkillsAsync,
  getLiteralsAsync,
  markAllNotificationsAsReadAsync,
  markNotificationAsReadAsync,
  reacceptTermsAndConditionsAsync,
  saveNotificationSettingsAsync,
  saveProfileAsync,
  toggleNotificationsAsync,
  updateSkillAsync,
  syncCalendarUpdate,
  syncMeetingProviderUpdate,
} from '../actions/account';

import { inviteProjectMembersAsync } from '../actions/project';

import { inviteCommunityMembersAsync } from '../actions/community';

import { inviteTabEntityMembersAsync } from '../actions/tab';

import { fetchUserProfileAsync } from '../actions/users';

import { fetchDiscussionsTagsAsync } from '../actions/discussion';

import { communities, persistCommunityConfig, selectedCommunity } from './community';

import { projectsReducer /* persistProjectConfig */ } from './project';

import { persistCombineReducers, createTransform } from 'redux-persist';

import { persistedSessionReducer } from './session';
import { buildFlagReducer, replaceLiteralsString } from '../store/utils';
import { createNewRequestAsync, fetchRequestsPendingFeedbackAsync } from 'redux/actions/request';

export const fetchSkillsFlag = buildFlagReducer(fetchSkillsAsync);

export const fetchCountriesFlag = buildFlagReducer(fetchCountriesAsync);

export const fetchSDGsFlag = buildFlagReducer(fetchSDGsAsync);

export const fetchProjectCategoriesFlag = buildFlagReducer(fetchProjectCategoriesAsync);

export const fetchCurrentUserFlag = buildFlagReducer(fetchCurrentUserAsync);

export const fetchNotificationsFlag = buildFlagReducer(fetchNotificationsAsync);

export const markNotificationAsReadFlag = buildFlagReducer(markNotificationAsReadAsync);

export const markAllNotificationsAsReadFlag = buildFlagReducer(markAllNotificationsAsReadAsync);

export const fetchMyStuffFlag = buildFlagReducer(fetchMyStuffAsync);

export const fetchNotificationSettingsFlag = buildFlagReducer(fetchNotificationSettingsAsync);

export const saveNotificationSettingsFlag = buildFlagReducer(saveNotificationSettingsAsync);

export const toggleNotificationsFlag = buildFlagReducer(toggleNotificationsAsync);

export const saveProfileFlag = buildFlagReducer(saveProfileAsync);

export const reacceptTermsAndConditionsFlag = buildFlagReducer(reacceptTermsAndConditionsAsync);

export const user = createReducer({} as User)
  .handleAction(
    syncMeetingProviderUpdate,
    (
      state: User,
      action: PayloadAction<
        'SYNC_MEETING_PROVIDER_UPDATE',
        { hasMeetingProvider: boolean; meetingProviderType: 0 | 1; meetingProviderAccount: string | null }
      >,
    ) => {
      return {
        ...state,
        hasMeetingProvider: action.payload.hasMeetingProvider,
        meetingProviderType: action.payload.meetingProviderType,
        meetingProviderAccount: action.payload.meetingProviderAccount,
      };
    },
  )
  .handleAction(
    syncCalendarUpdate,
    (
      state: User,
      action: PayloadAction<
        'SYNC_CALENDAR_UPDATE',
        {
          isCalendarSynchronized: boolean;
          synchronizedCalendarType: number;
          synchronizedCalendarAccount: string | null;
        }
      >,
    ) => {
      return {
        ...state,
        isCalendarSynchronized: action.payload.isCalendarSynchronized,
        synchronizedCalendarType: action.payload.synchronizedCalendarType,
        synchronizedCalendarAccount: action.payload.synchronizedCalendarAccount,
      };
    },
  )
  .handleAction(
    [fetchCurrentUserAsync.success, saveProfileAsync.success, reacceptTermsAndConditionsAsync.success],
    (
      user: User,
      action: PayloadAction<
        'FETCH_USER_DATA_SUCCESS | SAVE_PROFILE_SUCCESS | REACCEPT_TERMS_AND_CONDITIONS_SUCCESS',
        User
      >,
    ) => {
      return {
        ...user,
        ...action.payload,
        communities: user.communities,
        ownedProjects: user.ownedProjects,
        requests: user.requests,
        followedProjects: user.followedProjects,
        settings: {
          ...user.settings,
          notifications: {
            ...user.settings?.notifications,
            enabled: action.payload.settings.notifications.enabled,
          },
        },
      };
    },
  )
  .handleAction(
    clearHasPendingRequestsAsync.success,
    (user: User, action: PayloadAction<'CLEAR_HAS_PENDING_REQUESTS_SUCCESS', null>) => {
      return {
        ...user,
        pendingRequestIds: [],
      };
    },
  )
  .handleAction(fetchMyStuffAsync.success, (user: User, action: PayloadAction<'FETCH_MY_STUFF_SUCCESS', MyStuff>) => {
    return {
      ...user,
      communities: action.payload.communities,
      ownedProjects: action.payload.projects,
      followedProjects: action.payload.followedProjects,
      requests: action.payload.requests,
    };
  })
  .handleAction(
    fetchNotificationSettingsAsync.success,
    (user: User, action: PayloadAction<'FETCH_NOTIFICATION_SETTINGS_SUCCESS', NotificationSetting[]>) => {
      return {
        ...user,
        settings: {
          ...user.settings,
          notifications: {
            ...user.settings?.notifications,
            list: action.payload,
          },
        },
      };
    },
  )
  .handleAction(
    saveNotificationSettingsAsync.success,
    (user: User, action: PayloadAction<'SAVE_NOTIFICATION_SETTINGS_SUCCESS', NotificationSetting[]>) => {
      return {
        ...user,
        settings: {
          ...user.settings,
          notifications: {
            ...user.settings.notifications,
            list: user.settings.notifications.list.map((setting: NotificationSetting) => {
              const index = findIndex(propEq('id', setting.id), action.payload);
              if (index === -1) return setting;
              return action.payload[index];
            }),
          },
        },
      };
    },
  )
  .handleAction(
    toggleNotificationsAsync.success,
    (user: User, action: PayloadAction<'TOGGLE_NOTIFICATIONS_SUCCESS', { state: boolean }>) => {
      return {
        ...user,
        settings: {
          ...user.settings,
          notifications: {
            ...user.settings.notifications,
            enabled: action.payload.state,
          },
        },
      };
    },
  )
  .handleAction(
    fetchUserProfileAsync.success,
    (user: User, action: PayloadAction<'FETCH_USER_PROFILE_SUCCESS', UserProfile>) => {
      if (!user || user.id !== action.payload.id) return user;
      return {
        ...user,
        rawUser: {
          ...user.rawUser,
          linkedInUrl: action.payload.linkedInUrl,
        },
        linkedInUrl: action.payload.linkedInUrl,
      };
    },
  );

const userTransform = createTransform<User, User, { user: User; notifications: Notification[] }>(
  (state: User) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return DEFAULT_USER;
    }
    return {
      ...state,
      rawUser: {},
      ownedProjects: (state.ownedProjects || []).map(removeMyStuffItemDescription),
      followedProjects: (state.followedProjects || []).map(removeMyStuffItemDescription),
      communities: (state.communities || []).map(removeMyStuffItemDescription),
    };
  },
  (state: User) => {
    return state;
  },
  { whitelist: ['user'] },
);

export const notifications = createReducer([] as Notification[])
  .handleAction(
    fetchNotificationsAsync.success,
    (
      localNotifications: Notification[],
      action: PayloadAction<'FETCH_NOTIFICATIONS_SUCCESS', { skip: number; notifications: Notification[] }>,
    ) => {
      const { notifications, skip } = action.payload;
      if (skip === 0 && notifications.length === 0) return [];
      const res = [...localNotifications];
      for (let i = 0; i < notifications.length; i++) {
        res[i + skip] = notifications[i];
      }
      return res;
    },
  )
  .handleAction(
    markNotificationAsReadAsync.success,
    (
      localNotifications: Notification[],
      action: PayloadAction<'MARK_NOTIFICATION_AS_READ_SUCCESS', { id: number }>,
    ) => {
      return localNotifications.map(notification => {
        if (notification.id === action.payload.id) {
          return {
            ...notification,
            isRead: true,
          };
        }

        return notification;
      });
    },
  )
  .handleAction(markAllNotificationsAsReadAsync.success, (localNotifications: Notification[]) => {
    return localNotifications.map(notification => {
      return {
        ...notification,
        isRead: true,
      };
    });
  });

const notificationTransform = createTransform<
  Notification[],
  Notification[],
  { user: User; notifications: Notification[] }
>(
  (state: Notification[]) => {
    if (!gdprConsent(featuresCookieCategories.account)) {
      return [];
    }
    return state;
  },
  (state: Notification[]) => {
    return state;
  },
  { whitelist: ['notifications'] },
);

const skillsCategoriesReducer = createReducer([] as SkillCategory[])
  .handleAction(
    fetchSkillsAsync.success,
    (skillCategories: SkillCategory[], action: PayloadAction<'FETCH_SKILLS_SUCCESS', SkillCategory[]>) => {
      return action.payload;
    },
  )
  .handleAction(
    updateSkillAsync.success,
    (skillCategories: SkillCategory[], action: PayloadAction<'UPDATE_SKILL_SUCCESS', any>) => {
      const thisCategory = skillCategories.findIndex(
        (skillCategory: SkillCategory) => skillCategory.id === action.payload?.id,
      );
      const newCategories = [...skillCategories];
      Object.assign(newCategories[thisCategory], { isActive: action.payload?.isActive });
      return newCategories;
    },
  );

const skillsReducer = createReducer([] as Skill[]).handleAction(
  fetchSkillsAsync.success,
  (skills: Skill[], action: PayloadAction<'FETCH_SKILLS_SUCCESS', SkillCategory[]>) => {
    return action.payload.map((sc: SkillCategory) => sc.skills || []).reduce((acc, skills) => [...acc, ...skills], []);
  },
);

const discussionTagsReducer = createReducer([] as string[]).handleAction(
  fetchDiscussionsTagsAsync.success,
  (
    tags: string[],
    action: PayloadAction<'FETCH_DISCUSSIONS_TAGS_SUCCESS', { entityId: number; entityType: string; tags: string[] }>,
  ) => action.payload.tags,
);

export const currentProjectIdReducer = createReducer(0).handleAction(
  createNewRequestAsync.request,
  (currentProjectId: number, action: PayloadAction<'CREATE_NEW_REQUEST', number>) => action.payload,
);

export const literalsReducer = createReducer(literals['en-EN'] as Literals).handleAction(
  getLiteralsAsync.success,
  (
    literalsState: Literals,
    action: PayloadAction<'GET_LITERALS_SUCCESS', { language: Languages; literals: Literals }>,
  ) => {
    const replacements = [
      {
        target: '{100}',
        replacement: action.payload.literals.primitive_startup,
      },
      {
        target: '{101}',
        replacement: action.payload.literals.primitive_startups,
      },
      {
        target: '{200}',
        replacement: action.payload.literals.primitive_program,
      },
      {
        target: '{201}',
        replacement: action.payload.literals.primitive_programs,
      },
    ];
    return replaceLiteralsString(action.payload.literals, replacements);
  },
);
export const countriesReducer = createReducer([] as Country[]).handleAction(
  fetchCountriesAsync.success,
  (countries: Country[], action: PayloadAction<'FETCH_COUNTRIES_SUCCESS', Country[]>) => action.payload,
);

export const sdgsReducer = createReducer([] as SDG[]).handleAction(
  fetchSDGsAsync.success,
  (sdgs: SDG[], action: PayloadAction<'FETCH_SDGS_SUCCESS', SDG[]>) => action.payload,
);

export const projectCategoriesReducer = createReducer([] as ProjectCategory[]).handleAction(
  fetchProjectCategoriesAsync.success,
  (
    projectCategories: ProjectCategory[],
    action: PayloadAction<'FETCH_PROJECT_CATEGORIES_SUCCESS', ProjectCategory[]>,
  ) => action.payload,
);

type InvitationStatus = (EmailInvitationDto | AxiosError)[];

export const invitationStatusReducer = createReducer([] as InvitationStatus)
  .handleAction(
    inviteCommunityMembersAsync.success,
    (invitationStatus: InvitationStatus, action: PayloadAction<'INVITE_COMMUNITY_MEMBERS_SUCCESS', InvitationStatus>) =>
      action.payload,
  )
  .handleAction(
    inviteProjectMembersAsync.success,
    (invitationStatus: InvitationStatus, action: PayloadAction<'INVITE_PROJECT_MEMBERS_SUCCESS', InvitationStatus>) =>
      action.payload,
  )
  .handleAction(
    inviteTabEntityMembersAsync.success,
    (invitationStatus: InvitationStatus, action: PayloadAction<'INVITE_TABENTITY_MEMBERS_SUCCESS', InvitationStatus>) =>
      action.payload,
  );

const requestsPendingFeedbackReducer = createReducer([]).handleAction(
  fetchRequestsPendingFeedbackAsync.success,
  (requestsPendingFeedback: [], action: PayloadAction<'INVITE_COMMUNITY_MEMBERS_SUCCESS', []>) => action.payload,
);

const communityReducer = persistCombineReducers(persistCommunityConfig, {
  list: communities,
});

const projectReducer = combineReducers<{ list: Project[] }>({
  list: projectsReducer,
});

const persistDetailsConfig = {
  key: getPersistKey('details'),
  storage,
  whitelist: ['user', 'notifications'],
  transforms: [userTransform, notificationTransform],
};

const detailsReducer = persistCombineReducers<{ user: User; notifications: Notification[] }>(persistDetailsConfig, {
  user,
  notifications,
});

const sessionReducer = combineReducers({
  session: persistedSessionReducer,
});

const persistAccountReducer = {
  key: getPersistKey('applications'),
  storage,
  whitelist: ['applications', 'applicationDefinitions', 'selectedCommunity'],
  transforms: [applicationDefinitionsTransform, applicationsTransform],
};

const accountReducer = persistCombineReducers(persistAccountReducer, {
  selectedCommunity: selectedCommunity,
  communities: communityReducer,
  projects: projectReducer,
  details: detailsReducer,
  session: sessionReducer,
  skillsCategories: skillsCategoriesReducer,
  skills: skillsReducer,
  discussionTags: discussionTagsReducer,
  applications: applicationsReducer,
  applicationDefinitions: applicationDefinitionsReducer,
  invitationStatus: invitationStatusReducer,
  currentProjectId: currentProjectIdReducer,
  requestsPendingFeedback: requestsPendingFeedbackReducer,
});

export default accountReducer;
export type AccountState = ReturnType<typeof accountReducer>;
